<template>
  <fragment>
    <div class="flex flex-col items-center justify-center">
      <div class="w-full md:w-1/2 xl:w-2/5 space-y-4">
        <div id="group">
          <div class="text-sm font-semibold text-black whitespace-nowrap">
            Phone number
          </div>
          <div class="flex justify-between h-9">
            <div
              class="bg-primary rounded-l-lg flex w-11 items-center justify-center text-center cursor-pointer"
            >
              <font-awesome-icon icon="fa-solid fa-user" class=" text-white"/>
            </div>
            <input
              autofocus
              type="number"
              v-model="toPay.phone"
              class="w-full outline-none px-5 text-xl font-semibold text-black border-b-4 border-primary"
            />
            <div
              class="bg-primary rounded-r-lg flex w-11 items-center justify-center text-center cursor-pointer"
            >
              <font-awesome-icon icon="fa-solid fa-address-book" class="text-white"/>
            </div>
          </div>
        </div>
        <div id="group">
          <div class="text-sm font-semibold text-black whitespace-nowrap">
            Amount
          </div>
          <div class="flex justify-between h-9">
            <div
              class="flex bg-primary rounded-l-lg w-9 px-4 text-base font-bold items-center justify-center text-center text-white"
            >
              {{ $store.state.currency.kode_domestik ?? "Rp" }}
            </div>
            <input
              type="number"
              v-model="toPay.amount"
              class="w-full outline-none px-5 text-xl font-semibold text-black border-b-4 border-primary"
            />
          </div>
        </div>
        <div id="group">
          <div class="text-sm font-semibold text-black whitespace-nowrap">
            Note
            <span class="font-medium text-xs text-black2">(optional) </span>
          </div>
          <div class="flex justify-between h-9">
            <div
              class="bg-primary rounded-l-lg flex w-11 items-center justify-center text-center cursor-pointer"
            >
              <img :src="icNote" class="w-4" alt="lihat" />
            </div>
            <input
              type="text"
              v-model="toPay.note"
              class="w-full outline-none px-5 text-xl font-semibold text-black border-b-4 border-primary"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex space-x-5 justify-end w-full mt-20">
      <div
        @click="inquiry"
        class="bg-primary rounded-10 text-base text-white font-semibold py-2 px-9 cursor-pointer transition ease-in-out duration-300 hover:shadow-lg"
      >
        Next
      </div>
    </div>
  </fragment>
</template>

<script>
import { NumberFormat } from "@/utils";

export default {
  name: "ToTripwePayInput",
  data: () => ({
    icUser: require("@/assets/icons/ic-user.svg"),
    icArrow: require("@/assets/icons/ic-arrow-outline.svg"),
    icNote: require("@/assets/icons/ic-note.svg"),
    toPay: {
      tokenInquiry: '',
      tokenPayment: '',
      phone: '',
      amount: 0,
      note: '',
    },
  }),
  methods: {
    numberFormat: num => NumberFormat(num),
    next() {
      this.$router.push({ 
        name: 'ToTripwePayFinal', 
        params: { 
          user: this.toPay.phone, 
          data: this.toPay
        } 
      });
    },
    async inquiry(){
      this.$store.commit('setOverlayLoading', true);
      var fData = new FormData();
      fData.append("txtCari", this.toPay.phone);
      // {
      //   "payment_amount"  : this.toPay.amount,   // Nominal Transfer
      //   "virtual_account" : this.toPay.phone,                 // VA BNI Penerima
      //   "description"     : this.toPay.note,      // Keterangan Transfer
      //   "vapermata"       : this.$store.state.user?.va ?? '',             // VA BNI Pengirim
      //   "token"           : this.toPay.tokenPayment, // UUID
      //   "apa"             :"TRIPWE"
      // };
      const res = await this.$http.post(`${this.$apiTripwe}/ic/jetski-android-new/lihat_transfer_va_v15.php`,fData);
      
      this.$store.commit('setOverlayLoading', false);
      if(res.data?.result?.length > 0 && res.data?.result[0]?.id_user != null){
        this.toPay.dataInquiry = res.data.result[0];
        this.next();
      }else{
        this.$toasted.global.error("User not found");
      }
    },
    randomStr(min, max){
      return Math. floor(Math. random()*(max-min+1)+min);
    }
  },
  created() {
    var now = this.$moment();
    this.toPay.tokenInquiry = "TWCIQ" + now.format("YYYYMMDDHHmmss") + this.randomStr(1,9999);
    this.toPay.tokenPayment = "TWCIP" + now.format("YYYYMMDDHHmmss") + this.randomStr(1,9999);
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
};
</script>